import React, { useState } from "react";
import { Space, Spin, Table } from "antd";
import { message } from "antd";
import dateFormat from "dateformat";
import { NavLink } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { convert12HourTo24Hour, mergeTimeInDate } from "../../helpers/helpers";

const currentTime = moment();

export default function BookingTable(props) {
  const [loadingRecordId, setLoadingRecordId] = useState(null);
  let vendor_id = localStorage.getItem("venderId");
  const acceptRejectButton = (record) => {
    if (!record?._id) return;
    const { booking_time, booking_date } = record;
    const convertedDate = mergeTimeInDate(
      booking_date,
      convert12HourTo24Hour(booking_time)
    );

    if (
      currentTime.isAfter(convertedDate) ||
      record.booking_status !== "Pending"
    )
      return null;

    return (
      <>
        <button
          className="btn orangeborder orangecolor"
          onClick={() => Approve(record)}
          disabled={loadingRecordId?.id === record._id} // Disable button when loading
        >
          {loadingRecordId?.id === record._id &&
          loadingRecordId?.type === "approve" ? (
            <Spin />
          ) : (
            "Accept"
          )}
        </button>
        <button
          className="btn orangeborder orangecolor"
          onClick={() => RejectBooking(record)}
          disabled={loadingRecordId?.id === record._id} // Disable button when loading
        >
          {loadingRecordId?.id === record._id &&
          loadingRecordId?.type === "reject" ? (
            <Spin />
          ) : (
            "Reject"
          )}
        </button>
      </>
    );
  };
  const columns = [
    {
      title: "ID",
      render: (value) => value._id.slice(-4),
      sorter: {
        compare: (a, b) => a._id.localeCompare(b._id),
      },
    },
    {
      title: "Customer Name",
      render: (value) => value.first_name + " " + value.last_name,
      sorter: {
        compare: (a, b) => a.first_name.localeCompare(b.first_name),
      },
    },
    {
      title: "Date",
      render: (value) => dateFormat(value.booking_date, "mm/dd/yyyy"),
      sorter: {
        compare: (a, b) => a.booking_date.localeCompare(b.booking_date),
      },
    },
    {
      title: "Booking Time",
      render: (value) => value.booking_time,
      sorter: {
        compare: (a, b) => a.booking_time.localeCompare(b.booking_time),
      },
    },
    {
      title: "Travel to Client",
      render: (value) => (value.trevel_to_me ? "Yes" : "No"),
      sorter: {
        compare: (a, b) => a.trevel_to_me.localeCompare(b.trevel_to_me),
      },
    },
    {
      title: "Booking Type",
      render: (value) =>
        value.instant_booking ? "Instant Booking" : "Standard Booking",
      sorter: {
        compare: (a, b) => a.booking_time - b.booking_time,
      },
    },
    {
      title: "Services",
      render: (render) =>
        render.cartItems.map(function (name, index) {
          return (
            <span key={`demo_snap_${index}`}>
              {(index ? ", " : "") + name.service_name}
            </span>
          );
        }),
    },
    {
      title: "Bill",
      dataIndex: "total_bill",
    },

    {
      title: "Deposit",
      dataIndex: "total_deposit",
    },

    {
      title: "Status",
      dataIndex: "booking_status",
      render: (value, record) => {
        if (value === "Pending") {
          const convertedBookingTime = mergeTimeInDate(
            record.booking_date,
            convert12HourTo24Hour(record.booking_time)
          );

          return currentTime.isAfter(convertedBookingTime)
            ? "Cancelled"
            : value;
        }
        return value;
      },
      sorter: {
        compare: (a, b) => a.booking_status.localeCompare(b.booking_status),
      },
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "x",
      render: (text, record, index) => (
        <Space size="middle">
          {record.booking_status === "Pending" ? (
            acceptRejectButton(record)
          ) : record.booking_status === "Accepted" ? (
            <>
              <NavLink
                to={{
                  pathname: "/chat/" + vendor_id + "/" + record.user.user_id,
                  state: { record },
                }}
              >
                <button className="btn orangeborder orangecolor">Chat</button>
              </NavLink>
              <button
                className="btn orangeborder orangecolor"
                onClick={() =>
                  record.half_payment_booking === true
                    ? CompleteHalf(record)
                    : Complete(record)
                }
                disabled={loadingRecordId?.id === record._id} // Disable button when loading
              >
                {loadingRecordId?.id === record._id &&
                loadingRecordId?.type === "complete" ? (
                  <Spin />
                ) : (
                  "Complete"
                )}
              </button>
              <button
                className="btn orangeborder orangecolor"
                onClick={() => RejectBooking(record)}
                disabled={loadingRecordId?.id === record._id} // Disable button when loading
              >
                {loadingRecordId?.id === record._id &&
                loadingRecordId?.type === "reject" ? (
                  <Spin />
                ) : (
                  "Cancel"
                )}
              </button>
            </>
          ) : null}
        </Space>
      ),
    },
  ];
  const data = props.data;
  function onChange(pagination, filters, sorter, extra) {}

  const CompleteHalf = (data) => {
    setLoadingRecordId({ id: data._id, type: "complete" });
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/api/vendor/complete_pay_half_booking/` +
          data._id +
          "/" +
          data.vendor.vendor_id
      )
      .then((response) => {
        console.log(response, "half");

        if (response.data.success) {
          message.success("Completed Successfully");
          props.getAll();
        }
      })
      .catch(function (error) {
        message.error(error);
      })
      .finally(() => setLoadingRecordId(null)); // Reset loading state
  };
  const Complete = (data) => {
    setLoadingRecordId({ id: data._id, type: "complete" });
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/api/vendor/complete_booking/` +
          data._id +
          "/" +
          data.vendor.vendor_id
      )
      .then((response) => {
        console.log(response, "complete");
        if (response.data.success) {
          message.success("Completed Successfully");
          props.getAll();
        }
      })
      .catch(function (error) {
        message.error(error);
      })
      .finally(() => setLoadingRecordId(null)); // Reset loading state
  };

  const Approve = (data) => {
    setLoadingRecordId({ id: data._id, type: "approve" });
    if (data.half_payment_booking === true) {
      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}/api/vendor/accept_payhalf_booking/` +
            data._id +
            "/" +
            data.vendor.vendor_id
        )
        .then((response) => {
          if (response.data.success) {
            message.success("Accepted Successfully");
            props.getAll();
          }
        })
        .catch(function (error) {
          message.error(error);
        })
        .finally(() => setLoadingRecordId(null)); // Reset loading state
    } else if (data.normal_booking === true) {
      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}/api/vendor/accept_normal_booking/` +
            data._id +
            "/" +
            data.vendor.vendor_id,
          {
            user_id: data.user.user_id,
            booking_time: data.booking_time,
          }
        )
        .then((response) => {
          if (response.data.success) {
            console.log(response.data);
            message.success("Accepted Successfully");
            props.getAll();
          }
        })
        .catch(function (error) {
          message.error("Some error occurred");
        })
        .finally(() => setLoadingRecordId(null)); // Reset loading state
    }
  };

  const RejectBooking = (data) => {
    setLoadingRecordId({ id: data._id, type: "reject" });
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/api/vendor/reject_normal_booking/` +
          data._id +
          "/" +
          data.vendor.vendor_id
      )
      .then((response) => {
        if (response.data.success) {
          message.success("Rejected Successfully");
          props.getAll();
        }
      })
      .catch(function (error) {
        message.error(error);
      })
      .finally(() => setLoadingRecordId(null)); // Reset loading state
  };
  // eslint-disable-next-line no-unused-vars
  const Refund = (trnsID, total_bill) => {
    axios
      .post(
        `https://api-m.paypal.com/v1/payments/capture/${trnsID}/refund`,
        {
          amount: {
            total: total_bill,
            currency: "USD",
          },
          invoice_number: `INV-${Math.random()}`,
        },
        {
          auth: {
            username:
              "AatUi1CHp6Pnvrz8ptD_lHAG9LaPA0IJO06nxeKF74qqJaRag0NZL2-r7jNguvjedgyFz19mSSMkenMO",
            password:
              "EGjcIriLW13J36E_wt9gzuYWHVZcLDHf_IJXUt0VHBHmehXlyiEoR5UyQuDfdcCB68siOAZxjn3nrueS",
          },
        }
      )
      .then((res) => {
        console.log("refund Res", res);
      });
  };
  // eslint-disable-next-line no-unused-vars
  const split = (payment) => {
    axios
      .post(
        "https://api-m.paypal.com/v1/payments/payouts",
        {
          sender_batch_header: {
            sender_batch_id: Math.random(),
            email_subject: "Blackbooking_Payment!",
            email_message:
              "You have received a payment from blackbooking! Thanks for using our service!",
          },
          items: [
            {
              recipient_type: "EMAIL",
              amount: {
                value: payment,
                currency: "USD",
              },
              note: "Thanks for your patronage!",
              sender_item_id: "201403140001",
              receiver: localStorage.getItem("VenderPaypal"),
              alternate_notification_method: {
                phone: {
                  country_code: "92",
                  national_number: "3074474467",
                },
              },
              notification_language: "fr-FR",
            },
          ],
        },
        {
          auth: {
            username:
              "AatUi1CHp6Pnvrz8ptD_lHAG9LaPA0IJO06nxeKF74qqJaRag0NZL2-r7jNguvjedgyFz19mSSMkenMO",
            password:
              "EGjcIriLW13J36E_wt9gzuYWHVZcLDHf_IJXUt0VHBHmehXlyiEoR5UyQuDfdcCB68siOAZxjn3nrueS",
          },
        }
      )
      .then((res) => {
        console.log("split Res", res);
      });
  };

  return (
    <Table
      columns={columns}
      dataSource={data}
      onChange={onChange}
      className="table-responsive-sm"
    />
  );
}
