import React, { useCallback, useEffect, useState } from "react";
import BlackNavbar from "../SubComponents/BlackNavbar";

import { NavLink } from "react-router-dom";
import Payout from "../../API/Payouts/Payout";
import { message, Spin } from "antd";
import axios from "axios";

const URL = `${process.env.REACT_APP_BASE_URL}/api/vendor/all_vendors`;

const Payments = () => {
  const id = localStorage.getItem("venderId");
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState("");

  const getAllVendors = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axios.get(URL);
      if (!res.data.success) {
        return;
      }
      setEmail(res.data.data.find(({ _id }) => _id === id)?.email ?? "");
    } catch (err) {
      message.error(
        err?.response?.data?.message ?? "Something went wrong, try again later"
      );
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    getAllVendors();
  }, [getAllVendors]);

  if (loading) {
    return <Spin size="large" />;
  }

  return (
    <div>
      <BlackNavbar />
      <div className="col-12 Orange border196 pt-5">
        <div className="col-11 mxAuto maxWidth">
          <div className="col-11 mxAuto">
            <p className="White pt-5  font17">
              <NavLink to="/account" className="White">
                Account
              </NavLink>{" "}
              &gt;Payouts
            </p>
            <h1 className="White  pb-3">Payouts</h1>
          </div>
        </div>
      </div>

      <div className="maxWidth">
        <div className="col-11 row mxAuto pt-3 px-4">
          <div className="col-12 col-md-5 ">
            <h1 className="font22">
              Payout Method: <span className="font-weight-bold">PayPal</span>
            </h1>
            <div className="paddingTop_3">
              <label htmlFor="email">Paypal Email</label>
              <input
                type="text"
                className="fillcolor rounded-0 col-12 p-2 bordercolor"
                placeholder="Enter Stripe Registered Email"
                name="email"
                id="email"
                value={email}
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payments;
