import React from "react";
import BlackNavbar from "./SubComponents/BlackNavbar";
import headerimage from "../assests/headerimage.jpg";
import { NavLink } from "react-router-dom";
import googleicon from "../assests/googleicon.svg";
import { useState } from "react";
import GoogleLogin from "react-google-login";
import LoginGoogle from "../API/Login/LoginGoogle";
import { message, Modal } from "antd";
import OtpInput from "react-otp-input";
import axios from "axios";
import ReactPhoneInput from "react-phone-input-2";
import Confirmed from "../assests/confirmed.svg";
import { PasswordInput } from "./shared/PasswordInput";

const Signup = () => {
  const initialstate = {
    first_name: "",
    last_name: "",
    contact: "",
    email: "",
    password: "",
    google: "",
    gmail_login: false,
    update: false,
    otp: "",
    error: "",
    openSuccessModal: false,
  };

  const responseGoogle = (response) => {
    setFormData({
      ...FormData,
      first_name: response.profileObj?.givenName,
      last_name: response.profileObj?.familyName,
      email: response.profileObj?.email,
      password: "123456789",
      gmail_login: true,
    });
    LoginGoogle(
      response.profileObj?.givenName,
      response.profileObj?.familyName,
      response.profileObj?.email,
      "123456789",
      true
    );
  };

  const [FormData, setFormData] = useState(initialstate);
  const {
    first_name,
    last_name,
    contact,
    email,
    password,
    otp,
    update,
    error,
    openSuccessModal,
  } = FormData;

  const onHandleChange = (event) => {
    const { name, value } = event.target;
    if (event.target.name === "first_name") {
      setFormData({
        ...FormData,
        first_name: event.target.value.replace(/[^a-zA-Z\s]/gi, ""),
      });
    } else if (event.target.name === "last_name") {
      setFormData({
        ...FormData,
        last_name: event.target.value.replace(/[^a-zA-Z\s]/gi, ""),
      });
    } else if (event.target.name === "contact") {
      setFormData({
        ...FormData,
        contact: event.target.value.replace(/[^0-9]/gi, ""),
      });
    } else {
      setFormData({
        ...FormData,
        [name]: value,
        error: "",
      });
    }
  };

  const optChange = (opt) => {
    setFormData({
      ...FormData,
      otp: opt,
      error: "",
    });

    if (opt.length > 5) {
      VerifyCode(opt);
    }
  };
  const goToHome = () => {
    window.location = "/customerlogin";
  };

  const VerifyCode = (otpp) => {
    axios
      .put(`${process.env.REACT_APP_BASE_URL}/api/user/code_verification`, {
        email: email,
        confirmation_code: otpp,
      })
      .then((res) => {
        if (res.data.success) {
          setFormData({
            ...FormData,
            update: false,
          });
          setFormData({
            ...FormData,
            openSuccessModal: true,
          });
          // message.success("Account Created Successfully");
          localStorage.clear();
          // window.location = "/customerlogin";
        } else if (res.data.success === false) {
          message.error(res.data.error);
          setFormData({
            ...FormData,
            error: res.data.error,
          });
        }
      });
  };

  const UserSignup = (
    first_name,
    last_name,
    email,
    password,
    contact,
    gmail_login
  ) => {
    const URL = `${process.env.REACT_APP_BASE_URL}/api/user/signup`;
    axios
      .post(URL, {
        first_name: first_name,
        last_name: last_name,
        email: email,
        password: password,
        gmail_login: gmail_login,
      })
      .then((res) => {
        if (res.data.success) {
          setFormData({
            ...FormData,
            update: true,
          });
        } else {
          message.error(res.data.message);
        }
      });
  };

  const handleCancel = () => {
    setFormData({
      ...FormData,
      update: false,
    });
  };

  const ResendCode = () => {
    axios
      .put(`${process.env.REACT_APP_BASE_URL}/api/user/resend_code`, {
        email: email,
      })
      .then((res) => {
        if (res.data.success === true) {
          let resend = document.querySelector("#resend");

          resend.innerHTML = `Sent`;
        } else if (res.data.success === false) {
          message.error(res.data.message);
        }
      });
  };

  return (
    <div>
      <BlackNavbar />
      <div
        className="p-1 text-center bg-image  aboutusimage img-fluid col-12 pb-5 "
        style={{ backgroundImage: `url(${headerimage})` }}
      ></div>
      <div className=" col-lg-6 col-md-8 col-11 mxAuto bg-white loginform rounded-0 shadow pb-3">
        <h1 className="text-center m-0">Sign Up</h1>
        <form className="col-11 mxAuto">
          <div className=" row">
            <input
              type="text"
              className="bordercolor fillcolor pl-3 py-1 col-sm-6 col-12 rounded-0"
              placeholder="First Name"
              name="first_name"
              value={first_name}
              onChange={onHandleChange}
            />
            <input
              type="text"
              className="bordercolor  fillcolor pl-3 py-1 col-sm-6 col-12 rounded-0"
              placeholder="Last Name"
              name="last_name"
              value={last_name}
              onChange={onHandleChange}
            />
          </div>

          <div className=" row">
            <input
              type="text"
              className="bordercolor fillcolor pl-3 py-1 col-sm-6 col-12 rounded-0"
              placeholder="Email Address"
              name="email"
              value={email}
              onChange={onHandleChange}
            />

            <ReactPhoneInput
              inputExtraProps={{
                name: "contact",
                required: true,
                autoFocus: true,
              }}
              containerClass="bordercolor fillcolor pl-3 py-1 col-sm-6 col-12 rounded-0"
              defaultCountry={"sg"}
              value={contact}
              onChange={(text) => setFormData({ ...FormData, contact: text })}
              specialLabel={false}
              inputClass="border-0 bg-transparent"
            />
            {/* <input type="text" className="bordercolor fillcolor pl-3 py-1 col-sm-6 col-12 rounded-0" placeholder="Phone Number"
              name="contact"
              value={contact}
              onChange={onHandleChange} /> */}
          </div>
          <PasswordInput
            wrapperClass="form-group fillcolor"
            className="bordercolor fillcolor pl-3 py-1 rounded-0"
            placeholder="Password"
            name="password"
            value={password}
            minLength="8"
            onChange={onHandleChange}
            eyeIconWrapperStyle={{ top: 3 }}
          />
          <div className="form-group my-3 col-12">
            <div className="strike mxAuto">
              <h6>
                <span className="px-2">OR</span>
              </h6>
            </div>
          </div>
          <div className="d-inline-flex col-12 bg-white google rounded-0">
            <img
              src={googleicon}
              alt=""
              className="googlebtn py-auto my-auto"
            ></img>
            <GoogleLogin
              clientId="939117342067-ia4p451n6hi7o2nlj3u3u95j1t966s1f.apps.googleusercontent.com"
              render={(renderProps) => (
                <button
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  className="btn mx-auto"
                >
                  Sign Up with Google
                </button>
              )}
              buttonText="Login"
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy={"single_host_origin"}
            />
          </div>

          <button
            type="button"
            onClick={(e) =>
              UserSignup(first_name, last_name, email, password, contact)
            }
            className=" signupbtn mt-3 rounded-0"
          >
            <NavLink to="/signup" className="White">
              Sign Up
            </NavLink>
          </button>
          <p className="text-center pt-2">
            Already have an account?{" "}
            <NavLink to="/customerlogin" className="Red">
              Login
            </NavLink>
          </p>
        </form>
      </div>
      <Modal
        title={"Enter the Code"}
        className="OptPopUp"
        open={update}
        onCancel={handleCancel}
        footer={null}
      >
        {" "}
        {/* //width="80%" */}
        <p className="font10">Check your Email, 6-digit Code has been sent</p>
        <OtpInput
          value={otp}
          onChange={optChange}
          numInputs={6}
          separator={<span>-</span>}
        />
        <p className="font13 text-center text-danger">{error}</p>
        <p className="font13 blue float-right" id="resend" onClick={ResendCode}>
          Resend Code
        </p>
      </Modal>
      <Modal
        className="OptPopUp"
        open={openSuccessModal}
        footer={null}
        closable={false}
      >
        {" "}
        {/* //width="80%" */}
        <img
          src={Confirmed}
          alt="img"
          className="mx-auto d-block mb-3"
          style={{ width: "70px" }}
        />
        <p className="text-center font14 mb-4">
          Congratulations! Account Created Successfully!
        </p>
        <button
          className="btn Orange Radius_14 White mxAuto d-block"
          onClick={goToHome}
        >
          Login
        </button>
      </Modal>
    </div>
  );
};

export default Signup;
