import React, { Component } from "react";
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from "react-google-maps";
import Geocode from "react-geocode";
import Autocomplete from "react-google-autocomplete";
import { GoogleMapsAPI } from "./client-config";

Geocode.setApiKey(GoogleMapsAPI);
Geocode.enableDebug();

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address_line_1: "",
      city: "",
      // area: '',
      State: "",
      mapPosition: {
        lat: this.props.center.lat,
        lng: this.props.center.lng,
      },
      markerPosition: {
        lat: this.props.center.lat,
        lng: this.props.center.lng,
      },
    };
  }

  /**
   * Get the current address from the default map position and set those values in the state
   */

  componentDidMount() {
    Geocode.fromLatLng(
      this.state.mapPosition.lat,
      this.state.mapPosition.lng
    ).then(
      (response) => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          city = this.getCity(addressArray),
          //   area = this.getArea( addressArray ),
          state = this.getState(addressArray);
        this.setState({
          address_line_1: address ? address : "",
          // area: ( area ) ? area : '',
          city: city ? city : "",
          State: state ? state : "",
        });
      },
      (error) => {
        console.error(error);
      }
    );
  }

  /**
   * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
   *
   * @param nextProps
   * @param _nextState
   * @return {boolean}
   */
  shouldComponentUpdate(nextProps, _nextState) {
    return (
      _nextState.city.trim() !== this.state.city.trim() ||
      _nextState.address_line_1.trim() !== this.state.address_line_1.trim() ||
      _nextState.mapPosition.lat !== this.state.mapPosition.lat ||
      _nextState.mapPosition.lng !== this.state.mapPosition.lng
    );
  }

  /**
   * Get the city and set the city input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getCity = (addressArray) => {
    let city = "";
    for (const addressElement of addressArray) {
      // eslint-disable-next-line
      if (addressElement.types[0] && "locality" == addressElement.types[0]) {
        city = addressElement.long_name;
        return city;
      }
    }
  };
  /**
   * Get the area and set the area input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */

  /**
   * Get the address and set the address input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getState = (addressArray) => {
    let State = "";
    for (let i = 0; i < addressArray?.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "locality" === addressArray[i].types[0]
        ) {
          State = addressArray[i].long_name;
          return State;
        }
      }
    }
  };
  /**
   * And function for city,state and address input
   * @param event
   */
  onChange = (event) => {
    alert(event.target.name);
    this.setState({ [event.target.name]: event.target.value });
  };
  /**
   * This Event triggers when the marker window is closed
   *
   * @param event
   */
  onInfoWindowClose = (event) => {};

  /**
   * When the marker is dragged you get the lat and long using the functions available from event object.
   * Use geocode to get the address, city, area and state from the lat and lng positions.
   * And then set those values in the state.
   *
   * @param event
   */
  onMarkerDragEnd = (event) => {
    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng();

    Geocode.fromLatLng(newLat, newLng).then(
      (response) => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          city = this.getCity(addressArray),
          state = this.getState(addressArray);
        this.props.funt(event);
        this.setState({
          address_line_1: address ?? "",
          city: city ?? "",
          State: state ?? "",
          markerPosition: {
            lat: newLat,
            lng: newLng,
          },
          mapPosition: {
            lat: newLat,
            lng: newLng,
          },
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  /**
   * When the user types an address in the search box
   * @param place
   */
  onPlaceSelected = (place) => {
    const address = place?.formatted_address,
      addressArray = place?.address_components,
      city = this.getCity(addressArray),
      state = this.getState(addressArray),
      latValue = place?.geometry?.location?.lat(),
      lngValue = place?.geometry?.location?.lng();
    this.props.onChange(place);
    this.setState({
      address_line_1: address ?? "",
      city: city ?? "",
      State: state ?? "",
      markerPosition: {
        lat: latValue,
        lng: lngValue,
      },
      mapPosition: {
        lat: latValue,
        lng: lngValue,
      },
    });
  };
  options = {
    types: ["address"],
    componentRestrictions: { country: "us" },
  };

  render() {
    const AsyncMap = withScriptjs(
      withGoogleMap((props) => (
        <GoogleMap
          google={this.props.google}
          defaultZoom={this.props.zoom}
          scrollwheel={true}
          defaultCenter={{
            lat: this.state.mapPosition.lat,
            lng: this.state.mapPosition.lng,
          }}
        >
          <Autocomplete
            style={{
              position: "absolute",
              width: "100%",
              height: "30px",
              paddingLeft: "16px",
            }}
            className="top"
            placeholder="Enter your Location"
            options={this.options}
            onPlaceSelected={this.onPlaceSelected}
          />
          <Marker
            google={this.props.google}
            name={"Dolores park"}
            draggable={true}
            onDragEnd={this.onMarkerDragEnd}
            position={{
              lat: this.state.markerPosition.lat,
              lng: this.state.markerPosition.lng,
            }}
          />
          <Marker />
        </GoogleMap>
      ))
    );
    return this.props.center.lat ? (
      <div className="" style={{ position: "relative" }}>
        <AsyncMap
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GoogleMapsAPI}&libraries=places`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: this.props.height }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </div>
    ) : (
      <div style={{ height: this.props.height }} />
    );
  }
}

export default Map;
