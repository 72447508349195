import React, { useState } from "react";
import BlackNavbar from "../SubComponents/BlackNavbar";
import featuredBg from "../../assests/featuredBg.jpg";
import { NavLink } from "react-router-dom";
import googleicon from "../../assests/googleicon.svg";
import GoogleLogin from "react-google-login";
import LoginGoogle from "../../API/Login/LoginGoogle";
import { message } from "antd";
import OtpInput from "react-otp-input";
import { Modal } from "antd";
import Confirmed from "../../assests/confirmed.svg";
import axios from "axios";
import { EyeOutlined } from "@ant-design/icons";
import { PasswordInput } from "../shared/PasswordInput";

const CustomerLogin = () => {
  const initialstate = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    gmail_login: false,
    error: "",
    otp: "",
    openModel: false,
    openSuccessModal: false,
  };

  const [FormData, setFormData] = useState(initialstate);
  const { email, password, error, otp, openModel, openSuccessModal } = FormData;

  const onHandleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...FormData,
      [name]: value,
      error: "",
    });
  };

  const validation = () => {
    if (email !== "") {
      if (email.includes("@") && email.includes(".")) {
        if (password !== "") {
          Login();
        } else if (password === "") {
          setFormData({
            ...FormData,
            error: "Password is Required",
          });
          message.error("Password is Required");
        }
      } else {
        setFormData({
          ...FormData,
          error: "Email is not valid",
        });
        message.error("Email is not valid");
      }
    } else if (email === "") {
      setFormData({
        ...FormData,
        error: "Email is Required",
      });
      message.error("Email is Required");
    }
  };

  const Login = () => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/user/login`, {
        email: email,
        password: password,
      })
      .then((res) => {
        if (res.data.success) {
          setFormData({
            ...FormData,
            openSuccessModal: true,
          });
          localStorage.setItem("type", "user");
          localStorage.setItem("userId", res.data.data._id);
        } else if (res.data.success === false) {
          if (
            res.data.message ===
            "Email is not verified.Verification code is sent at your email address"
          ) {
            setFormData({
              ...FormData,
              openModel: true,
            });
          }
          message.error(res.data.message);
        }
      });
  };

  const responseGoogle = (response) => {
    setFormData({
      ...FormData,
      first_name: response.profileObj?.givenName,
      last_name: response.profileObj?.familyName,
      email: response.profileObj?.email,
      password: "123456789",
      gmail_login: true,
    });
    LoginGoogle(
      response.profileObj?.givenName,
      response.profileObj?.familyName,
      response.profileObj?.email,
      "123456789",
      true
    );
  };

  const handleCancel = () => {
    setFormData({
      ...FormData,
      openModel: false,
    });
  };

  const optChange = (opt) => {
    setFormData({
      ...FormData,
      otp: opt,
      error: "",
    });

    if (opt.length > 5) {
      VerifyCode(opt);
    }
  };

  const VerifyCode = (otpp) => {
    axios
      .put(`${process.env.REACT_APP_BASE_URL}/api/user/code_verification`, {
        email: email,
        confirmation_code: otpp,
      })
      .then((res) => {
        console.log(res);
        if (res.data.success) {
          setFormData({
            ...FormData,
            openSuccessModal: true,
          });
          localStorage.setItem("type", "user");
          localStorage.setItem("userId", res.data.data._id);
        } else if (res.data.success === false) {
          message.error(res.data.error);
          setFormData({
            ...FormData,
            error: res.data.error,
          });
        }
      });
  };

  const ResendCode = () => {
    axios
      .put(`${process.env.REACT_APP_BASE_URL}/api/user/resend_code`, {
        email: email,
      })
      .then((res) => {
        if (res.data.success) {
          message.success("sent");
        }
      });
  };

  const goToHome = () => {
    window.location = "/";
  };
  const Enterr = (e) => {
    if (e.key === "Enter") {
      validation();
    }
  };

  return (
    <div>
      <BlackNavbar />
      <div
        className="p-1  bg-image img-fluid col-12 pb-5 "
        style={{ backgroundImage: `url(${featuredBg})` }}
      >
        <div className=" col-lg-5 col-md-8 col-11 mxAuto bg-white px-0 shadow mt-5">
          <div className="bgGrey ">
            <h1 className="text-center m-0">Customer Login</h1>
          </div>
          <div className="col-11 mxAuto ">
            <div>
              <input
                type="email"
                className="col-12 bordercolor loginemail fillcolor pl-4"
                placeholder="Username or Email Address"
                required
                name="email"
                value={email}
                onChange={onHandleChange}
              />
            </div>
            <PasswordInput
              wrapperClass="pt-2"
              className="bordercolor loginpassword mb-2"
              placeholder="Password"
              required
              name="password"
              value={password}
              onChange={onHandleChange}
              onKeyDown={Enterr}
            />

            <NavLink to="/userforgetPassword" className="Black col-12 ">
              Forget Password?
            </NavLink>
            <button
              type="button"
              id="btnSearch"
              className="signupbtn mt-3 rounded-0"
              onClick={validation}
            >
              Login
            </button>
            <p
              className="text-danger text-center mb-0 py-2"
              style={{ fontSize: "20px", fontWeight: "500" }}
            >
              {" "}
              {error}
            </p>
          </div>
          <div className="form-group my-3 col-11 mxAuto">
            <div className="strike mxAuto">
              <h6>
                <span className="px-2">OR</span>
              </h6>
            </div>
          </div>
          <div className="form-group col-11 mxAuto pt-3 pb-5">
            <div className="d-inline-flex col-12 bg-white google rounded-0">
              <img
                src={googleicon}
                alt=""
                className="googlebtn py-auto my-auto"
              ></img>
              <GoogleLogin
                clientId="939117342067-ia4p451n6hi7o2nlj3u3u95j1t966s1f.apps.googleusercontent.com"
                render={(renderProps) => (
                  <button
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                    className="btn mx-auto"
                  >
                    Sign in with Google
                  </button>
                )}
                buttonText="Login"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={"single_host_origin"}
              />
            </div>
          </div>
        </div>

        <Modal
          title={"Enter the Code"}
          className="OptPopUp"
          open={openModel}
          onCancel={handleCancel}
          footer={null}
        >
          {" "}
          {/* //width="80%" */}
          <p className="font10">Check your Email, 6-digit Code has been sent</p>
          <OtpInput
            value={otp}
            onChange={optChange}
            numInputs={6}
            separator={<span>-</span>}
          />
          <p className="font13 text-center text-danger">{error}</p>
          <p className="font13 blue float-right" onClick={ResendCode}>
            Resend Code
          </p>
        </Modal>

        <Modal
          className="OptPopUp"
          open={openSuccessModal}
          footer={null}
          closable={false}
        >
          {" "}
          {/* //width="80%" */}
          <img
            src={Confirmed}
            alt="img"
            className="mx-auto d-block mb-3"
            style={{ width: "70px" }}
          />
          <p className="text-center font14 mb-4">
            Congratulations! you are Logged In
          </p>
          <button
            className="btn Orange Radius_14 White mxAuto d-block"
            onClick={goToHome}
          >
            Continue
          </button>
        </Modal>
      </div>
    </div>
  );
};

export default CustomerLogin;
