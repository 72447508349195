import React, { useEffect, useState } from "react";
import BlackNavbar from "../SubComponents/BlackNavbar";
import axios from "axios";
import { message, Spin } from "antd";
import ChatVendor from "../../API/Chat/ChatVendor";

import $ from "jquery";
export default function VendorChat(props) {
  const initialstate = {
    user_id: props.match.params.user_id,
    vendor_id: localStorage.getItem("venderId"),
    message1: "",
    allMessages: [],
    update: false,
  };
  const [FormData, setFormData] = useState(initialstate);
  const { user_id, vendor_id, message1, allMessages, update } = FormData;
  // eslint-disable-next-line
  if (allMessages.length == 0) {
  }

  useEffect(() => {
    getMessages();
    // eslint-disable-next-line
  }, []);

  const getMessages = () => {
    const link =
      `${process.env.REACT_APP_BASE_URL}/api/message/` +
      user_id +
      `/` +
      vendor_id;
    axios.get(link).then((response) => {
      if (response.data.success === true) {
        if (response.data.message !== "No message yet") {
          setFormData({
            ...FormData,
            allMessages: response.data.data,
            message1: "",
            update: true,
          });
        } else {
          setFormData({
            ...FormData,
            allMessages: [],
            update: true,
          });
        }
        $("#chatBox").scrollTop($("#chatBox")[0]?.scrollHeight);
      } else {
        message.error(response.data.message);
      }
    });
    // .catch(function (error) {
    //     message.error(error);
    // });
  };

  const onHandleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...FormData,
      [name]: value,
    });
  };

  // eslint-disable-next-line
  const RenderChats = allMessages.map((item) => {
    if (item.vendor_message === true) {
      return (
        // eslint-disable-next-line
        <>
          <div class="col-8 ml-auto" style={{ maxWidth: "75%" }}>
            <p
              className="Orange White ml-auto Radius_20 px-3 py-2"
              style={{ width: "fit-content", maxWidth: "100%" }}
            >
              {item.message}
            </p>
          </div>
        </>
      );
    } else if (item.user_message === true) {
      return (
        <>
          <div class="col-8" style={{ maxWidth: "75%" }}>
            <p
              className="backgroundGrey Black mr-auto Radius_20 px-3 py-2"
              style={{ width: "fit-content", maxWidth: "100%" }}
            >
              {item.message}
            </p>
          </div>
        </>
      );
    }
  });

  if (update !== true) {
    return <Spin size="large" />;
  } else if (update === true) {
    return (
      <>
        <BlackNavbar />
        <div className="backgroundGrey pb-5">
          <p className="text-center font29 pt-3 pt-md-4">
            Chat with {props.location.state.record.user.first_name}{" "}
            {props.location.state.record.user.last_name}{" "}
          </p>
          <div className="maxWidth mxAuto pb-5">
            <p
              className="col-lg-6 col-md-7 col-12 mx-auto text-right text-danger font-weight-bold font24"
              style={{ cursor: "pointer" }}
              onClick={() => props.history.goBack()}
            >
              &times;
            </p>
            <div className="col-11 mxAuto">
              <div
                className="col-lg-7 col-md-8 col-12 mx-auto scroll"
                id="chatBox"
              >
                <div className="row">
                  {allMessages.length !== 0 ? (
                    RenderChats
                  ) : (
                    <p>Start Chatting Now</p>
                  )}
                </div>
              </div>
              <div className="col-lg-7 col-md-8 col-12 row mxAuto paddingTop_3  ">
                <input
                  type="text"
                  name="message1"
                  value={message1}
                  className="col-9 mr-auto Radius_7 p-2 "
                  placeholder="Write Message"
                  onChange={onHandleChange}
                ></input>
                <button
                  className="btn orangecolor orangeborder col-2 ml-auto p-2"
                  onClick={() =>
                    ChatVendor(user_id, vendor_id, message1, getMessages)
                  }
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
