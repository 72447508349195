import BlackNavbar from "./SubComponents/BlackNavbar";
import BusinessForm from "./SubComponents/ListYourBussiness/BussinessForm";
import Geocode from "react-geocode";
import React from "react";
import RenderMapComp from "./SubComponents/ListYourBussiness/RenderMap";
import axios from "axios";
import featuredBg from "../assests/featuredBg.jpg";
import { message } from "antd";
import { timeAvail } from "./SubComponents/ListYourBussiness/TimeSlotsAvail";
import { toast } from "react-hot-toast";
import { testPassword } from "../helpers/helpers";
import { passwordStrengthMapper } from "../helpers/constants";

class ListYourBusiness extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      VerificationImages: [],
      first_name: "",
      last_name: "",
      email: "",
      contact: "",
      password: "",
      password_confirmation: "",
      payment_type: "",
      paypalEmail: "",
      business_name: "",
      website: "",
      city: "",
      State: "",
      lat: "",
      lng: "",
      lati: "",
      long: "",
      mapLat: "",
      mapLng: "",
      markerLat: "",
      markerLng: "",
      address_line_1: "",
      address_line_2: "",
      service_name: "",
      service_price: "",
      service_time: "",
      filteredServices: [],
      services: [],
      working_hours: timeAvail,
      WiFi: false,
      Book: false,
      trevel_to_me: false,
      Facebook: "",
      Instagram: "",
      error: "",
      openModel: false,
      loading: true,
      passwordError: "",
      passwordStrength: null,
    };
    this.onHandleChange = this.onHandleChange.bind(this);
    this.imagesSet = this.imagesSet.bind(this);
  }

  componentDidMount() {
    this.addressChange(this.state.markerLat, this.state.markerLng);
    window.scrollTo(0, 0);
  }

  working_hours_close = (index) => {
    let working_hours = this.state.working_hours;
    working_hours[index].closed = !working_hours[index].closed;
    this.setState({ working_hours });
  };

  working_hours_time = (index, time, date) => {
    let working_hours = this.state.working_hours;
    working_hours[index].time = time;
    working_hours[index].date = date;
    this.setState({ working_hours });
  };

  imagesSet = (imageArray) => {
    this.setState({
      images: imageArray,
      error: "",
    });
  };

  verification = (imageArray) => {
    this.setState({
      VerificationImages: imageArray,
      error: "",
    });
  };

  onNumberChange = (value) => {
    this.setState({
      contact: value,
    });
  };

  onHandleChange = (event) => {
    const { name, type, checked } = event.target;
    if (type === "checkbox") {
      this.setState({
        [name]: checked,
        error: "",
      });
    } else if (event.target.name === "first_name") {
      this.setState({
        first_name: event.target.value.replace(/[^a-zA-Z\s]/gi, ""),
      });
    } else if (event.target.name === "last_name") {
      this.setState({
        last_name: event.target.value.replace(/[^a-zA-Z\s]/gi, ""),
      });
    } else if (event.target.name === "contact") {
      this.setState({
        contact: event.target.value.replace(/[^0-9]/gi, ""),
      });
    } else if (event.target.name === "service_name") {
      this.setState({
        service_name: event.target.value.replace(/[^a-zA-Z\s]/gi, ""),
      });
    } else if (event.target.name === "service_price") {
      if (parseInt(event.target.value) < parseInt(this.state.service_deposit)) {
        message.error("Service price cannot be less than deposit fee");
        this.setState({
          service_price: this.state.service_deposit,
        });
      } else {
        this.setState({
          service_price: event.target.value.replace(/[^0-9]/gi, ""),
        });
      }
    } else if (event.target.name === "service_time") {
      const value = Math.max(
        1,
        Number(event.target.value.replace(/[^0-9]/gi, ""))
      );
      this.setState({
        service_time: value,
      });
    } else if (event.target.name === "service_deposit") {
      if (parseInt(event.target.value) > parseInt(this.state.service_price)) {
        message.error("Deposit fee cannot be greator than service price");
        this.setState({
          service_deposit: this.state.service_price,
        });
      } else {
        this.setState({
          service_deposit: event.target.value.replace(/[^0-9]/gi, ""),
        });
      }
    } else {
      this.setState({
        [name]: event?.currentTarget?.value,
        error: "",
      });

      if (["password"].includes(name)) {
        this.checkPasswordStrength(false, event?.currentTarget?.value);
      }
    }
  };

  onWiFiChange = (e) => {
    this.setState({
      WiFi: e,
      error: "",
    });
  };

  onBookChange = (e) => {
    this.setState({
      Book: e,
      error: "",
    });
  };

  onTravelChange = (e) => {
    this.setState({
      trevel_to_me: e,
      error: "",
    });
  };

  addressChange = (lat, lng) => {
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const address = response?.results[0]?.formatted_address,
          addressArray = response.results[0].address_components,
          city = this.getCity(addressArray),
          state = this.getState(addressArray);
        this.setState({
          address_line_1: address ?? "",
          city: city ?? "",
          State: state ?? "",
          markerLat: lat,
          markerLng: lng,
          mapLat: lat,
          mapLng: lng,
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  // Abdul Hai Code Start
  onMarkerDragEnd = (event) => {
    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng();
    this.addressChange(newLat, newLng);
  };

  onPlaceChange = (place) => {
    // eslint-disable-next-line
    const address = place?.formatted_address,
      addressArray = place?.address_components,
      // addressLength = place?.address_components?.length,
      city = place?.formatted_address.split(",")[1],
      state = this.getState(addressArray),
      // state = addressArray[addressLength - 1].long_name,
      latValue = place.geometry.location.lat(),
      lngValue = place.geometry.location.lng();
    this.setState({
      address_line_1: address ? address : "",
      city: city ? city : "",
      State: state ? state : "",
      markerLat: latValue,
      markerLng: lngValue,
      mapLat: latValue,
      mapLng: lngValue,
    });
  };

  getCity = (addressArray) => {
    let city = "";
    for (let i = 0; i < addressArray?.length; i++) {
      // eslint-disable-next-line
      if (addressArray[i].types[0] && "locality" == addressArray[i].types[0]) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };

  getState = (addressArray) => {
    let State = "";
    const addressLength = addressArray?.length;
    if (
      addressArray[addressLength - 5].types[0] === "administrative_area_level_1"
    ) {
      State = addressArray[addressLength - 5].long_name;
      return State;
    } else if (
      addressArray[addressLength - 4].types[0] === "administrative_area_level_1"
    ) {
      State = addressArray[addressLength - 4].long_name;
      return State;
    } else if (
      addressArray[addressLength - 3].types[0] === "administrative_area_level_1"
    ) {
      State = addressArray[addressLength - 3].long_name;
      return State;
    } else if (
      addressArray[addressLength - 2].types[0] === "administrative_area_level_1"
    ) {
      State = addressArray[addressLength - 2].long_name;
      return State;
    } else {
      State = addressArray[addressLength - 1].long_name;
      return State;
    }
  };
  // Abdul Hai Code End

  Service = () => {
    if (this.state.payment_type === "full") {
      if (
        this.state.service_name !== "" &&
        this.state.service_price !== "" &&
        this.state.service_time !== ""
      ) {
        const random = Math.floor(100_000 + Math.random() * 900_000);
        const newService = {
          serviceid: random,
          service_name: this.state.service_name,
          service_price: this.state.service_price,
          service_deposit: 0,
          service_time: parseInt(this.state.service_time),
        };
        this.state.services.push(newService);
        this.setState({
          service_name: "",
          service_price: "",
          service_time: "",
          service_deposit: "",
        });
      }
    } else if (
      this.state.service_name !== "" &&
      this.state.service_price !== "" &&
      this.state.service_time !== "" &&
      this.state.service_deposit
    ) {
      const random = Math.floor(100000 + Math.random() * 900000);
      const newService = {
        serviceid: random,
        service_name: this.state.service_name,
        service_price: this.state.service_price,
        service_deposit: this.state.service_deposit,
        service_time: parseInt(this.state.service_time),
      };
      this.state.services.push(newService);
      this.setState({
        service_name: "",
        service_price: "",
        service_time: "",
        service_deposit: "",
      });
    }
  };

  onUpdateName = (event) => {
    const id = +event.target.id.replace("service_name", "");
    this.setState({
      services: this.state.services.map((service) => {
        if (service.serviceid === id) {
          return { ...service, service_name: event.target.value };
        }
        return service;
      }),
    });
  };

  onUpdatePrice = (event) => {
    const id1 = +event.target.id.replace("service_price", "");

    this.setState({
      services: this.state.services.map((item) => {
        if (item.serviceid === id1) {
          if (parseInt(item.service_deposit) > parseInt(event.target.value)) {
            message.error("Service price cannot be less than deposit fee");
            return item;
          } else {
            return {
              ...item,
              service_price: event.target.value.replace(/[^0-9]/gi, ""),
            };
          }
        }
        return item;
      }),
    });
  };

  onUpdatetime = (event) => {
    const id1 = +event.target.id.replace("service_time", "");
    // eslint-disable-next-line
    this.setState({
      services: this.state.services.map((service) => {
        // eslint-disable-next-line
        if (service.serviceid === id1) {
          const value = Math.max(
            1,
            Number(event.target.value.replace(/[^0-9]/gi, ""))
          );
          return { ...service, service_time: value };
        }
        return service;
      }),
    });
  };

  onUpdateDescription = (event) => {
    const id1 = +event.target.id.replace("service_description", "");
    this.setState({
      services: this.state.services.map((service) => {
        if (service.serviceid === id1) {
          return {
            ...service,
            service_description: event.target.value,
          };
        }
        return service;
      }),
    });
  };

  onUpdatedeposit = (event) => {
    const id1 = +event.target.id.replace("service_deposit", "");
    this.setState({
      services: this.state.services.map((service) => {
        // eslint-disable-next-line
        if (service.serviceid == id1) {
          if (parseInt(service.service_price) < parseInt(event.target.value)) {
            message.error("Deposit fee cannot be greater than service price");
            // TODO: uncomment if need to replace with service_price
            // return { ...service, service_deposit: service.service_price }
          } else {
            return {
              ...service,
              service_deposit: Math.max(
                0,
                Number(event.target.value.replace(/[^0-9]/gi, ""))
              ),
            };
          }
        }
        return service;
      }),
    });
  };

  checkPasswordStrength = (showToast = true, value = this.state.password) => {
    this.setState({
      passwordError: "",
      passwordStrength: null,
    });
    let [errorMessage, strength] = ["", null];
    console.log(value);

    strength = testPassword(value);

    if (strength < 2) {
      errorMessage = `The password is ${passwordStrengthMapper.get(strength)}`;
    }

    this.setState({
      passwordError: errorMessage,
      error: errorMessage,
      passwordStrength: strength,
    });

    if (showToast && errorMessage) {
      message.error(errorMessage);
    }

    return errorMessage.length > 0 || strength < 2;
  };

  validation = () => {
    const { password, password_confirmation } = this.state;
    this.setState({
      error: "",
    });

    if (password.length < 6) {
      this.setState({ error: "Password must be at least 6 characters" });
      message.error("Password must be at least 6 characters");
      return;
    }
    if (password !== password_confirmation) {
      this.setState({
        error: "Password and confirm password must be the same",
      });
      message.error("Password and confirm password must be the same");
      return;
    }

    if (this.checkPasswordStrength()) {
      return;
    }

    if (this.state.first_name !== "") {
      if (this.state.last_name !== "") {
        if (this.state.email !== "") {
          if (
            this.state.email.includes("@") &&
            this.state.email.includes(".")
          ) {
            if (this.state.password !== "") {
              if (this.state.payment_type !== "") {
                if (this.state.paypalEmail !== "") {
                  if (this.state.business_name !== "") {
                    if (this.state.city !== "") {
                      if (this.state.State !== "") {
                        if (this.state.address_line_1 !== "") {
                          if (
                            this.state.markerLat !== "" ||
                            this.state.markerLng !== ""
                          ) {
                            if (
                              this.state.service_name === "" &&
                              this.state.service_price === "" &&
                              this.state.service_time === "" &&
                              this.state.services.length > 0
                            ) {
                              if (
                                this.state.images.length !== 0 &&
                                this.state.images.length !== 1 &&
                                this.state.images.length !== 2 &&
                                this.state.images.length !== 3
                              ) {
                                // eslint-disable-next-line
                                this.state.filteredServices = [];
                                // eslint-disable-next-line
                                this.state.services.map(
                                  // eslint-disable-next-line array-callback-return
                                  (service) => {
                                    if (
                                      service.service_name !== "" &&
                                      service.service_price !== ""
                                    ) {
                                      this.state.filteredServices.push(service);
                                    } else {
                                      message.error(
                                        "Incomplete service information will Discard the Service"
                                      );
                                    }
                                  }
                                );

                                if (
                                  this.state.VerificationImages.length !== 0 &&
                                  this.state.VerificationImages.length !== 1
                                ) {
                                  this.BusinessSignup1();
                                  this.setState({
                                    error: "",
                                  });
                                } else {
                                  this.setState({
                                    error: "Provide Verification Images",
                                  });
                                  message.error("Provide Verification Images");
                                }
                              } else if (this.state.images.length <= 3) {
                                this.setState({
                                  error: "Atleast 4 images are Required",
                                });
                                message.error("Atleast 4 images are Required");
                              }
                            } else if (
                              this.state.service_name !== "" &&
                              this.state.service_price !== "" &&
                              this.state.service_time !== ""
                            ) {
                              this.Service();
                              if (
                                this.state.images.length !== 0 &&
                                this.state.images.length !== 1 &&
                                this.state.images.length !== 2 &&
                                this.state.images.length !== 3
                              ) {
                                // eslint-disable-next-line
                                this.state.filteredServices = [];
                                // eslint-disable-next-line
                                this.state.services.map(
                                  // eslint-disable-next-line array-callback-return
                                  (service) => {
                                    if (
                                      service.service_name !== "" &&
                                      service.service_price !== ""
                                    ) {
                                      this.state.filteredServices.push(service);
                                    } else {
                                      message.error(
                                        "Incomplete service information will Discard the Service"
                                      );
                                    }
                                  }
                                );

                                if (this.state.VerificationImages.length > 1) {
                                  this.BusinessSignup1();
                                  this.setState({
                                    error: "",
                                  });
                                } else {
                                  this.setState({
                                    error: "Provide Verification Images",
                                  });
                                  message.error("Provide Verification Images");
                                }
                              } else if (this.state.images.length <= 1) {
                                this.setState({
                                  error: "Atleast 4 images are Required",
                                });
                                message.error("Atleast 4 images are Required");
                              }
                            } else if (timeAvail.length < 7) {
                              this.setState({
                                error:
                                  "Please add at least 7 days of timeslots",
                              });
                              message.error(
                                "Please add at least 7 days of timeslots"
                              );
                            }
                            // eslint-disable-next-line
                            else if (
                              this.state.service_name === "" ||
                              this.state.service_price === "" ||
                              (this.state.service_time === "" &&
                                this.state.services.length === 0)
                            ) {
                              this.setState({
                                error: "Services are Required",
                              });
                              message.error("Services are Required");
                            }
                          } else if (
                            this.state.markerLat === "" ||
                            this.state.markerLng === ""
                          ) {
                            this.setState({
                              error: "Select the Correct Location from Map",
                            });
                            message.error(
                              "Select the Correct Location from Map"
                            );
                          }
                        } else if (this.state.address_line_1 === "") {
                          this.setState({
                            error: "Select the Location from Map",
                          });
                          message.error("Select the Location from Map");
                        }
                      } else if (this.state.State === "") {
                        this.setState({
                          error: "Select the Location from Map",
                        });
                        message.error("Select the Location from Map");
                      }
                    } else if (this.state.city === "") {
                      this.setState({
                        error: "Select the Location from Map",
                      });
                      message.error("Select the Location from Map");
                    }
                  } else if (this.state.business_name === "") {
                    this.setState({
                      error: "Business Name is Required",
                    });
                    message.error("Business Name is Required");
                  }
                } else if (this.state.paypalEmail === "") {
                  this.setState({
                    error: "Payment Email and ID must be provided for payments",
                  });
                  message.error("Payment Email must be provided for payments");
                }
              } else if (this.state.payment_type === "") {
                this.setState({
                  error: "Payment Type Must be Selected",
                });
                message.error("Payment Type Must be Selected");
              }
            } else if (this.state.password === "") {
              this.setState({
                error: "Password is Required",
              });
              message.error("Password is Required");
            }
          } else {
            this.setState({
              error: "Email is not valid",
            });
            message.error("Email is not valid");
          }
        } else if (this.state.email === "") {
          this.setState({
            error: "Email is Required",
          });
          message.error("Email is Required");
        }
      } else if (this.state.last_name === "") {
        this.setState({
          error: "Last Name is Required",
        });
        message.error("Last Name is Required");
      }
    } else if (this.state.first_name === "") {
      this.setState({
        error: "First Name is Required",
      });
      message.error("First Name is Required");
    }
  };

  handleCancel = () => {
    this.setState({
      openModel: false,
    });
  };

  BusinessSignup1 = () => {
    if (this.state.working_hours.length < 7) {
      message.error("Please add at least 7 days of timeslots");
      return;
    }
    this.setState({
      openModel: true,
    });
    const URL = `${process.env.REACT_APP_BASE_URL}/api/vendor/signup`;
    // const formData = new FormData();
    // this.state.images.forEach((item) => {
    //   formData.append("images", item);
    // });
    // this.state.VerificationImages.forEach((item) => {
    //   formData.append("verification_images", item);
    // });
    // // formData.append('images', this.state.images);
    // // formData.append('verification_images', this.state.VerificationImages);

    // formData.append("first_name", this.state.first_name);
    // formData.append("last_name", this.state.last_name);
    // formData.append("email", this.state.email);
    // formData.append("password", this.state.password);
    // formData.append("contact", this.state.contact);
    // formData.append("payment_type", this.state.payment_type);
    // formData.append("paypal_email", this.state.paypalEmail);
    // formData.append("business_name", this.state.business_name);
    // formData.append("website", this.state.website);
    // formData.append("city", this.state.city);
    // formData.append("State", this.state.State);
    // formData.append("address_line_1", this.state.address_line_1);
    // formData.append("lat", this.state.mapLat);
    // formData.append("lng", this.state.mapLng);
    // formData.append("services", this.state.filteredServices);
    // formData.append("working_hours", this.state.working_hours);
    // formData.append("wifi", this.state.WiFi);
    // formData.append("instant_booking", this.state.Book);
    // formData.append("facebook_link", this.state.Facebook);
    // formData.append("instagram_link", this.state.Instagram);
    // formData.append("trevel_to_me", this.state.trevel_to_me);

    axios
      .post(
        URL,
        {
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          email: this.state.email,
          password: this.state.password,
          contact: this.state.contact,
          payment_type: this.state.payment_type,
          paypal_email: this.state.paypalEmail,
          business_name: this.state.business_name,
          website: this.state.website,
          city: this.state.city,
          State: this.state.State,
          address_line_1: this.state.address_line_1,
          lat: this.state.mapLat,
          lng: this.state.mapLng,
          services: this.state.filteredServices,
          working_hours: this.state.working_hours,
          wifi: this.state.WiFi,
          instant_booking: this.state.Book,
          facebook_link: this.state.Facebook,
          instagram_link: this.state.Instagram,
          trevel_to_me: this.state.trevel_to_me,
        }
        // , {
        //         headers: { "Content-Type": "multipart/form-data" },
        //       }
      )
      .then(async (res) => {
        if (res.data.success) {
          await sendImages(res.data.data._id);
          sendVerificationImages(res.data.data._id);
        } else {
          message.error(res.data.message);
          this.setState({
            openModel: false,
          });
        }
      });
    const sendImages = async (id) => {
      let formData = new FormData();
      this.state.images.map((img) =>
        formData.append("images", img?.originFileObj)
      );
      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}/api/vendor/update_images/` + id,
          formData
        )
        .then((response) => {
          if (response.data.success === true) {
            this.setState({
              openModel: false,
            });
            toast.success(
              "Vendor Created Successfully, but admin approval is pending",
              "10"
            );
            this.setState({
              images: [],
              VerificationImages: [],
              first_name: "",
              last_name: "",
              email: "",
              contact: "",
              password: "",
              payment_type: "",
              paypalEmail: "",
              business_name: "",
              website: "",
              city: "",
              State: "",
              lat: "",
              lng: "",
              lati: "",
              long: "",
              mapLat: "",
              mapLng: "",
              markerLat: "",
              markerLng: "",
              address_line_1: "",
              address_line_2: "",
              service_name: "",
              service_price: "",
              service_time: "",
              filteredServices: [],
              services: [],
              working_hours: timeAvail,
              WiFi: false,
              Book: false,
              trevel_to_me: false,
              Facebook: "",
              Instagram: "",
              error: "",
              openModel: false,
              loading: true,
            });
            setTimeout(() => {
              window.location.replace("/vendorlogin");
            }, 3000);
          }
          // eslint-disable-next-line
          else if (response.data.success === false) {
            this.setState({
              openModel: false,
            });
            message.error(response.data.error);
          }
        });

      // .catch(function (error) {
      //   message.error("error");
      //   this.setState({
      //     openModel: false,
      //   });
      // });
    };

    const sendVerificationImages = (id) => {
      let formData = new FormData();
      this.state.VerificationImages.map((img) =>
        formData.append("verification_image", img?.originFileObj)
      );
      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}/api/vendor/verification_image/` +
            id,
          formData
        )
        .then((response) => {
          if (response.success === true) {
            localStorage.setItem("type", "vendor");
            localStorage.setItem("venderId", id);
            this.setState({
              openModel: false,
            });
            toast.success(
              "Vendor Created Successfully, but admin approval is pending",
              "10"
            );
            this.setState({
              images: [],
              VerificationImages: [],
              first_name: "",
              last_name: "",
              email: "",
              contact: "",
              password: "",
              payment_type: "",
              paypalEmail: "",
              business_name: "",
              website: "",
              city: "",
              State: "",
              lat: "",
              lng: "",
              lati: "",
              long: "",
              mapLat: "",
              mapLng: "",
              markerLat: "",
              markerLng: "",
              address_line_1: "",
              address_line_2: "",
              service_name: "",
              service_price: "",
              service_time: "",
              filteredServices: [],
              services: [],
              working_hours: timeAvail,
              WiFi: false,
              Book: false,
              trevel_to_me: false,
              Facebook: "",
              Instagram: "",
              error: "",
              openModel: false,
              loading: true,
            });
            setTimeout(() => {
              window.location.reload();
              window.scrollTo(0, 0);
            }, 3000);
          }
          // eslint-disable-next-line
          else if (response.data.success == false) {
            this.setState({
              openModel: false,
            });
            message.error(response.data.error);
          }
        })

        .catch(function (error) {
          message.error("error");
        });
    };
  };

  render() {
    console.log(this.state.working_hours, this.state.images);
    const removeService = (id) => {
      const serviceArr = [...this.state.services];
      let myArr = serviceArr.filter((item) => {
        return item.serviceid !== id;
      });
      this.setState({ services: myArr });
    };

    const RenderMap = this.state.services.map((servicemap) => {
      return (
        <RenderMapComp
          key={servicemap.serviceid}
          servicemap={servicemap}
          onUpdateName={this.onUpdateName}
          onUpdatePrice={this.onUpdatePrice}
          onUpdatetime={this.onUpdatetime}
          onUpdatedeposit={this.onUpdatedeposit}
          removeService={removeService}
          onUpdateDescription={this.onUpdateDescription}
        />
      );
    });

    return (
      <>
        <BlackNavbar />
        <div className=" col-11 mxAuto pt-3">
          <img src={featuredBg} alt="img" className="businessimg" />
        </div>
        <BusinessForm
          onHandleChange={this.onHandleChange}
          onNumberChange={this.onNumberChange}
          RenderMap={RenderMap}
          first_name={this.state.first_name}
          last_name={this.state.last_name}
          email={this.state.email}
          paypalEmail={this.state.paypalEmail}
          contact={this.state.contact}
          password={this.state.password}
          password_confirmation={this.state.password_confirmation}
          business_name={this.state.business_name}
          website={this.state.website}
          address_line_1={this.state.address_line_1}
          city={this.state.city}
          State={this.state.State}
          onPlaceChange={this.onPlaceChange}
          onMarkerDragEnd={this.onMarkerDragEnd}
          addressChange={this.addressChange}
          google={this.props.google}
          working_hours_close={this.working_hours_close}
          working_hours={this.state.working_hours}
          working_hours_time={this.working_hours_time}
          service_name={this.state.service_name}
          service_price={this.state.service_price}
          service_time={this.state.service_time}
          service_deposit={this.state.service_deposit}
          error={this.state.error}
          openModel={this.state.openModel}
          loading={this.state.loading}
          book={this.state.Book}
          WiFi={this.state.WiFi}
          trevel_to_me={this.state.trevel_to_me}
          Service={this.Service}
          imagesSet={this.imagesSet}
          onBookChange={this.onBookChange}
          onWiFiChange={this.onWiFiChange}
          onTravelChange={this.onTravelChange}
          verification={this.verification}
          validation={this.validation}
          payment_type={this.state.payment_type}
          services={this.state.services}
          mapLat={this.state.mapLat}
          mapLng={this.state.mapLng}
          passwordError={this.state.passwordError}
          passwordStrength={this.state.passwordStrength}
        />
      </>
    );
  }
}

export default ListYourBusiness;
