import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { useState } from "react";

export const PasswordInput = (props) => {
  const {
    wrapperClass = "",
    eyeIconWrapperClassName = "",
    eyeIconWrapperStyle = {},
    className = "",
    ...rest
  } = props;

  console.log(rest);

  const [showPassword, setShowPassword] = useState(false);

  const onToggle = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <div className={`${wrapperClass} position-relative`}>
      <input
        {...rest}
        type={showPassword ? "text" : "password"}
        className={`col-12 fillcolor pl-4 ${className}`}
        value={rest?.value || ""}
      />
      <span
        className={eyeIconWrapperClassName}
        onClick={onToggle}
        style={{
          right: 10,
          top: 20,
          position: "absolute",
          cursor: "pointer",
          ...eyeIconWrapperStyle,
        }}
      >
        {showPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
      </span>
    </div>
  );
};
