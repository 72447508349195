import moment from "moment";

/**
 *
 * @param {string} pwd
 * @description Returns the current strength of the password
 * @returns {number} 0: weird, 1: weak, 2: ok, 3: strong, 4: awesome
 */
export const testPassword = (pwd, min = 6) => {
  const simplePasswords = [
    "123456",
    "123123",
    "qwerty",
    "asdfgh",
    "zxcvbn",
    "qweasdzxc",
    "qwerty123",
    "asdfghjkl",
    "zxcvbnm",
    "qwertyuiop",
    "asdfghjklzxcvbnm",
    "321321",
    "1234567890",
    "qwertyuiopasdfghjklzxcvbnm",
    "1234567890qwertyuiop",
    "qwertyuiopasdfghjklzxcvbnm1234567890",
    "admin",
    "admin123",
    "admin321",
    "asdf123",
    "123abc",
    "abc123",
    "654321",
    "012345",
    "password",
    "123pass",
    "pass123",
    "123456abc",
  ];

  let level = 0;

  if (simplePasswords.includes(pwd) || pwd.length < min) {
    return level;
  }
  const array = [];
  array[0] = pwd.match(/[A-Z]/);
  array[1] = pwd.match(/[a-z]/);
  array[2] = pwd.match(/\d/);
  array[3] = pwd.match(/[!_.-@,|"']/);

  let sum = 0;
  for (const element of array) {
    sum += element ? 1 : 0;
  }

  /**
      sum = 0: weird...
      sum = 1: weak
      sum = 2: ok
      sum = 3: strong
      sum = 4: awesome
  */

  switch (sum) {
    case 0:
      level = 0;
      break;
    case 1:
      level = 1;
      break;
    case 2:
      level = 2;
      break;
    case 3:
      level = 3;
      break;
    case 4:
      level = 4;
      break;
    default:
      level = 0;
      break;
  }

  return level;
};

/**
 *
 * @param {string} time
 */
export const convert12HourTo24Hour = (time) => {
  let [hours, minutes] = time.replace(/am|pm|AM|PM/g, "").split(":");
  if (!time || typeof time !== "string" || !minutes) {
    return time;
  }
  const unit = time.toLowerCase().endsWith("am") ? "am" : "pm";

  if (hours === "12") {
    hours = "00";
  }

  if (unit === "pm") {
    hours = parseInt(hours, 10) + 12;
  }

  if (parseInt(hours, 10) < 12 && hours.length === 1) {
    hours = `0${hours}`;
  }

  return `${hours}:${minutes}`;
};

export const mergeTimeInDate = (date, time) => {
  const dateToMoment = moment(date).format("YYYY-MM-DDT");
  return `${dateToMoment}${time}:00.000Z`;
};
