import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import UserBookings from "./User/UserBookings";
import UserAccount from "./User/UserAccount";
import UserPersonalInfo from "./User/UserPersonalInfo";
import InviteFriends from "./User/InviteFriends";
import UserNotifications from "./User/UserNotifications";
import UserSecurity from "./User/UserSecurity";
import PrivacyandSharing from "./User/PrivacyandSharing";
import SelectReason from "./User/DeactivateAccount/SelectReason";
import Deactivate from "./User/DeactivateAccount/Deactivate";
import LiveNotifications from "./User/LiveNotifications";
import UserChat from "./User/UserChat";
import PaymentBooking from "./PaymentBooking";
const UserLinks = () => {
  return (
    <Switch>
      <Route exact path="/account" component={UserAccount} />
      <Route exact path="/personalinfo/:_id" component={UserPersonalInfo} />
      <Route exact path="/security/:_id" component={UserSecurity} />
      <Route exact path="/notifications" component={UserNotifications} />
      <Route exact path="/privacyandsharing" component={PrivacyandSharing} />
      <Route exact path="/invite/:_id" component={InviteFriends} />
      <Route exact path="/userbookings/:_id" component={UserBookings} />
      <Route exact path="/chat/:_id" component={UserChat} />
      <Route exact path="/paymentbooking/:_id" component={PaymentBooking} />
      <Route exact path="/selectreason/:_id" component={SelectReason} />
      <Route exact path="/deactivate/:_id" component={Deactivate} />
      <Route exact path="/notification/:_id" component={LiveNotifications} />
      <Redirect to="/" />
    </Switch>
  );
};

export default UserLinks;
