import React, { useState } from "react";
import confirmed from "../../assests/confirmed.svg";
import { NavLink } from "react-router-dom";
import Report from "../../API/UserBooking/Report";
import ReactStars from "react-rating-stars-component";
import Reviews from "../../API/UserBooking/Reviews";
import { Space, Table } from "antd";
import dateFormat from "dateformat";
import axios from "axios";
import moment from "moment";
import { convert12HourTo24Hour, mergeTimeInDate } from "../../helpers/helpers";

const currentTime = moment();

export default function PreviousTable(props) {
  const initialstate = {
    remarks: "",
    reviews: "",
    stars: "",
    vendor_id: "",
    booking_id: "",
    user_id: "",
    visible: false,
  };

  const [FormData, setFormData] = useState(initialstate);
  const { remarks, reviews, stars, vendor_id, user_id } = FormData;
  const cancelButton = (record) => {
    if (!record?._id) return;
    const { booking_time, booking_date } = record;
    const convertedDate = mergeTimeInDate(
      booking_date,
      convert12HourTo24Hour(booking_time)
    );

    if (
      currentTime.isAfter(convertedDate) ||
      record.booking_status !== "Pending"
    )
      return null;
    return (
      <button
        className="btn orangeborder orangecolor"
        onClick={() => DeleteBooking(record?._id)}
      >
        Cancel Booking
      </button>
    );
  };

  const showReportModal = (e) => {
    setFormData({
      ...FormData,
      vendor_id: e.vendor.vendor_id,
      user_id: e.user.user_id,
      booking_id: e._id,
      remarks: "",
    });
  };

  const showReviewModal = (e) => {
    setFormData({
      ...FormData,
      vendor_id: e.vendor.vendor_id,
      user_id: e.user.user_id,
      booking_id: e._id,
      reviews: "",
      stars: 0,
    });
  };

  const ratingChanged = (stars) => {
    setFormData({
      ...FormData,
      stars: stars,
    });
  };

  const onHandleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...FormData,
      [name]: value,
    });
  };

  const columns = [
    {
      title: "ID",
      render: (value) => value._id.slice(-4),
      sorter: {
        compare: (a, b) => a._id.localeCompare(b._id),
      },
    },
    {
      title: "Buisness Name",
      render: (value) => value.vendor.business_name,
      sorter: {
        compare: (a, b) =>
          a.vendor.business_name.localeCompare(b.vendor.business_name),
      },
    },
    {
      title: "Date",
      render: (value) => dateFormat(value.booking_date, "mm/dd/yyyy"),
      sorter: {
        compare: (a, b) => a.booking_date.localeCompare(b.booking_date),
      },
    },
    {
      title: "Booking Time",
      dataIndex: "booking_time",
      sorter: {
        compare: (a, b) => a.booking_time - b.booking_time,
      },
    },
    {
      title: "Booking Type",
      render: (value) =>
        value.instant_booking ? "Instant Booking" : "Standard Booking",
      sorter: {
        compare: (a, b) => a.booking_time - b.booking_time,
      },
    },
    {
      title: "Services",
      render: (render) =>
        render.cartItems.map(function (name, index) {
          return (
            <span key={`demo_snap_${index}`}>
              {(index ? ", " : "") + name.service_name}
            </span>
          );
        }),
    },
    {
      title: "Bill",
      render: (value) => <>${value.total_bill} </>,
    },
    {
      title: "Deposit",
      render: (value) => <>${value.total_deposit} </>,
    },
    {
      title: "Booking Status",
      dataIndex: "booking_status",
      render: (value, record) => {
        if (value === "Pending") {
          const convertedBookingTime = mergeTimeInDate(
            record.booking_date,
            convert12HourTo24Hour(record.booking_time)
          );

          return currentTime.isAfter(convertedBookingTime)
            ? "Cancelled"
            : value;
        }
        return value;
      },
      sorter: {
        compare: (a, b) => a.booking_time - b.booking_time,
      },
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "x",
      render: (text, record, index) =>
        record.booking_status === "Completed" ? (
          <Space size="middle">
            <button
              className="btn orangeborder orangecolor"
              data-toggle="modal"
              data-target="#report"
              onClick={(e) => showReportModal(record)}
            >
              Report
            </button>
            <div
              className="modal fade"
              id="report"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalCenterTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-body pt-4">
                    <p className="text-center Black">Explain your report</p>
                    <div className="col-10 mxAuto pt-2">
                      <textarea
                        rows="8"
                        placeholder="Please Explain your experience"
                        className="ProfileArea fillcolor col-12 Radius_7 bordercolor p-2"
                        name="remarks"
                        value={remarks}
                        onChange={onHandleChange}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-12 justify-content-center pb-5">
                    <button
                      type="button"
                      className="btn Orange White p-1 w-50 d-block mxAuto Radius_10"
                      data-toggle="modal"
                      data-target="#report"
                      onClick={(e) => Report(remarks, vendor_id, user_id)}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <button
              id="code"
              className="btn White Orange"
              data-toggle="modal"
              data-target="#review"
              onClick={(e) => showReviewModal(record)}
              style={{
                fontSize: 12,
              }}
            >
              Review
            </button>
            <div
              className="modal fade"
              id="review"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalCenterTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog  modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-body pt-4">
                    <p className="text-center Black">
                      Please Submit Your Review
                    </p>
                    <div className="col-md-5 mxAuto">
                      <ReactStars
                        count={5}
                        onChange={ratingChanged}
                        size={20}
                        value={stars}
                        activeColor="#FF0000"
                      />
                    </div>
                    <div className="col-10 mxAuto pt-2">
                      <textarea
                        rows="8"
                        placeholder="Your Review here"
                        className="ProfileArea fillcolor col-12 Radius_7 bordercolor"
                        name="reviews"
                        value={reviews}
                        onChange={onHandleChange}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-12 justify-content-center pb-5">
                    <button
                      type="button"
                      className="btn Orange White p-1 w-50 d-block mxAuto Radius_10"
                      data-toggle="modal"
                      data-target="#exampleModal1"
                      onClick={(e) => {
                        Reviews(vendor_id, user_id, stars, reviews);
                      }}
                    >
                      Submit Review
                    </button>
                    <div
                      className="modal fade "
                      id="exampleModal1"
                      tabindex="-1"
                      role="dialog"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div
                        className="modal-dialog modal-dialog-centered my-auto"
                        role="document"
                      >
                        <div className="modal-content ">
                          <img
                            src={confirmed}
                            alt=""
                            className="w-25 mxAuto pt-3"
                          ></img>
                          <div className="modal-body ">
                            <p className="  text-center col-8 mxAuto font17">
                              Your Review Has been Submitted Successfully
                            </p>
                          </div>
                          <NavLink
                            to="/userbookings"
                            className="col-12 justify-content-center pb-5"
                          >
                            <button
                              type="button"
                              data-dismiss="modal"
                              className="btn Orange White p-2 w-50 d-block mxAuto Radius_10"
                            >
                              OK
                            </button>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Space>
        ) : record.booking_status === "Pending" ? (
          cancelButton(record)
        ) : (
          record.booking_status === "Accepted" && (
            <div style={{ display: "flex" }}>
              <NavLink
                to={{
                  pathname: "/chat/" + record.user.user_id,
                  state: { record },
                }}
              >
                <button className="btn orangeborder orangecolor">Chat</button>
              </NavLink>
              {cancelButton(record)}
            </div>
          )
        ),
    },
  ];
  const DeleteBooking = (id) => {
    const link =
      `${process.env.REACT_APP_BASE_URL}/api/user/delete_booking?id=` + id;

    axios.delete(link).then((response) => {
      console.log(response);
      props.getAllBookings();

      // if (response.data.success) {
      //   setFormData({
      //     ...FormData,
      //     All: response.data.data,
      //     update: true,
      //   });
      // } else {
      //   setFormData({
      //     ...FormData,
      //     All: [],
      //     update: true,
      //   });
      // }
    });
  };
  const data = props.data;
  return (
    <Table
      columns={columns}
      dataSource={data}
      className="table-responsive-sm"
    />
  );
}
