import { FacebookFilled, InstagramFilled } from "@ant-design/icons";
import { Input, Modal, Spin, Switch, message } from "antd";

import CalenderCus from "../CalenderCus";
import Map from "../../Map";
import { ReactComponent as PlusIcon } from "../../../assests/plus.svg";
import React, { memo } from "react";
import ReactPhoneInput from "react-phone-input-2";
import UploadComponent2 from "../UploadComponent2";
import { PasswordInput } from "../../shared/PasswordInput";

const MemoizedMap = memo(Map);

const BusinessForm = (props) => {
  const serviceChange = () => {
    if (props.payment_type === "full") {
      props.Service();
      return;
    }
    if (parseInt(props.service_price) < parseInt(props.service_deposit)) {
      message.error("deposit is not greater than actual price");
    } else {
      props.Service();
    }
  };

  // const passwordBorderColor = () => {
  //   console.log(props.passwordStrength);
  //   if (props.passwordStrength === undefined) {
  //     return "";
  //   }
  //   if (props.passwordStrength < 2) {
  //     return "border-danger";
  //   }
  //   if (props.passwordStrength === 2) {
  //     return "border-warning";
  //   }
  //   if (props.passwordStrength > 2) {
  //     return "border-success";
  //   }
  // };

  return (
    <div className="maxWidth">
      <div className="row col-md-11 col-12 mxAuto pt-5 ">
        <div className="col-md-10 col-12 col-lg-7 mxAuto padding_5 bg-white earn shadow rounded-0">
          <h1 className=" font39 bdrBotom py-2">
            Earn Money by Getting Bookings from{" "}
            <span style={{ color: "#ea9223" }}>Blackbooking</span>
          </h1>
          <h3 className="text-center mt-0 mt-md-4 mb-0">
            Register Your Business
          </h3>

          <form>
            <h6 className="mt-4 mb-2 Bold">Personal Information</h6>
            <div className="row">
              <input
                type="text"
                data-toggle="tooltip"
                data-placement="left"
                title="First Name Required"
                className="rounded-0 fillcolor p-2 col-sm-6 col-12 mb-0 bordercolor"
                placeholder="First name"
                name="first_name"
                value={props.first_name}
                onChange={props.onHandleChange}
              />

              <input
                type="text"
                data-toggle="tooltip"
                data-placement="right"
                title="Last Name Required"
                className="rounded-0 fillcolor p-2 col-sm-6 col-12  mb-0 bordercolor"
                placeholder="Last name"
                name="last_name"
                value={props.last_name}
                onChange={props.onHandleChange}
              />
            </div>

            <div className="row">
              <input
                type="email"
                data-toggle="tooltip"
                data-placement="left"
                title="Email Required"
                className="rounded-0 fillcolor  p-2 col-sm-6 col-12  mb-0 bordercolor"
                placeholder="Email"
                name="email"
                value={props.email}
                onChange={props.onHandleChange}
              />
              <ReactPhoneInput
                inputExtraProps={{
                  name: "contact",
                  required: true,
                  autoFocus: true,
                }}
                containerClass="rounded-0 fillcolor p-2 col-sm-6 col-12  mb-0 bordercolor reactPhone"
                defaultCountry={"sg"}
                value={props.contact}
                onChange={props.onNumberChange}
                specialLabel={false}
                inputClass="border-0 bg-transparent p-0"
              />
            </div>
            <PasswordInput
              wrapperClass="pt-0"
              data-toggle="tooltip"
              data-placement="left"
              title="Password Must be minimum 6 digits"
              className={`rounded-0 fillcolor p-2 bordercolor mb-0`}
              style={{ marginBottom: "0 !important" }}
              placeholder="Password"
              name="password"
              value={props.password}
              onChange={props.onHandleChange}
              eyeIconWrapperStyle={{
                top: 7,
              }}
            />
            <PasswordInput
              wrapperClass="pt-0"
              data-toggle="tooltip"
              data-placement="left"
              title="Password Confirmation must be minimum 6 digits and same as password"
              className={`rounded-0 fillcolor p-2 mb-0 bordercolor ${
                props?.password_confirmation
                  ? props.password === props.password_confirmation
                    ? "border-success"
                    : "border-danger"
                  : ""
              }`}
              placeholder="Confirm Password"
              name="password_confirmation"
              value={props.password_confirmation}
              onChange={props.onHandleChange}
              eyeIconWrapperStyle={{
                top: 7,
              }}
            />
            {props.passwordError ? (
              <p className="text-danger my-2 small">{props.passwordError}</p>
            ) : null}
          </form>

          <h6 className="mt-2 Bold">Payment Type</h6>
          <div className="col-12 px-0">
            <ul className="col-12 col-md-12 mx-auto combineButton form RadioToButton d-inline-flex px-0 mb-4">
              <li className="w-50">
                <input
                  type="radio"
                  id="full"
                  name="payment_type"
                  value="full"
                  onClick={props.onHandleChange}
                />
                <label htmlFor="full" className="py-2 mb-0 Bold cursor">
                  Full
                </label>
              </li>
              <li className="w-50">
                <input
                  type="radio"
                  id="deposit"
                  name="payment_type"
                  value="deposit"
                  onClick={props.onHandleChange}
                />
                <label htmlFor="deposit" className="py-2 mb-0 Bold cursor">
                  Deposit
                </label>
              </li>
              <li className="w-50">
                <input
                  type="radio"
                  id="both"
                  name="payment_type"
                  value="both"
                  onClick={props.onHandleChange}
                />
                <label htmlFor="both" className="py-2 mb-0 Bold cursor">
                  Both
                </label>
              </li>
            </ul>
          </div>
          <h6 className="mt-2 Bold">Enter your paypal account</h6>
          <div className="row">
            <div className="col-12 px-0">
              <input
                type="text"
                data-toggle="tooltip"
                data-placement="left"
                title="Paypal Email"
                className="rounded-0 fillcolor p-2 col-12  mb-0 bordercolor"
                name="paypalEmail"
                placeholder="Enter your Paypal Email"
                value={props.paypalEmail}
                onChange={props.onHandleChange}
              />
            </div>
          </div>

          <h6 className="mt-2 mb-2 Bold">Business Information</h6>

          <form>
            <div className="mb-3 d-flex  flex-wrap">
              <input
                type="text"
                data-toggle="tooltip"
                data-placement="left"
                title="Business Name Required"
                className="rounded-0 fillcolor p-2  col-12 col-md-6  mb-0 bordercolor "
                name="business_name"
                placeholder="Business Name"
                value={props.business_name}
                onChange={props.onHandleChange}
              />
              <input
                type="text"
                data-toggle="tooltip"
                data-placement="left"
                title="Business Website Required"
                className="rounded-0 fillcolor p-2  col-12 col-sm-6  mb-0 bordercolor"
                name="website"
                placeholder="Business Website"
                value={props.website}
                onChange={props.onHandleChange}
              />
            </div>
            <div
              style={{
                marginTop: "4rem",
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <div className="d-flex gap-1 display">
                <input
                  type="text"
                  readOnly
                  name="city"
                  placeholder="City from Map"
                  className="rounded-0 fillcolor p-2 col-sm-6 col-12  mb-0 bordercolor"
                  // onChange={() => this.props.onHandleChange()}
                  value={props.city}
                />
                <input
                  type="text"
                  readOnly
                  name="state"
                  placeholder="State from Map"
                  className="rounded-0 fillcolor p-2 col-sm-6 col-12 mb-0 bordercolor"
                  // onChange={() => this.props.onHandleChange()}
                  value={props.State}
                />
              </div>
              <div style={{ marginTop: "0rem" }}>
                <input
                  type="text"
                  name="address_line_1"
                  className="rounded-0 fillcolor p-2 col-12  mb-0 bordercolor"
                  onChange={props.onHandleChange}
                  value={props.address_line_1}
                  placeholder="Address"
                />
              </div>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "8px" }}
            >
              <div className="mapHeight">
                <MemoizedMap
                  onChange={props.onPlaceChange}
                  address={props.address_line_1}
                  google={props.google}
                  center={{
                    lat: props?.mapLat || 40.711391223589956,
                    lng: props?.mapLng || -74.00283887121081,
                  }}
                  funt={props.onMarkerDragEnd}
                  city={props.city}
                  State={props.State}
                  addressChange={props.onHandleChange}
                  getAddress={props.addressChange}
                  height="300px"
                  zoom={14}
                />
              </div>
            </div>
          </form>

          <h6 className="mt-5 mb-2 Bold pt-3">
            Please Select All Opening Time Slots For Each Day
            <div>
              <CalenderCus />
            </div>
          </h6>
          <div />

          <h6 className="mt-4 mb-2 Bold">Add Service Menu</h6>

          <div className="row">
            <div className="col-12">
              {props.services?.length ? (
                <div className="row mb-2">
                  <p className="p-2 col-sm-4 col-12 px-0 py-0 mb-0">
                    Service Name
                  </p>
                  <p className="p-2 col-sm-4 col-12 px-0 py-0 mb-0">
                    Service Price $
                  </p>
                  <p className="p-2 col-sm-2 col-6 px-0 py-0 mb-0">Duration</p>
                  <p className="p-2 col-sm-2 col-6 px-0 py-0 mb-0">Deposit</p>
                </div>
              ) : null}
            </div>
            <div className="col-12 mx-0 px-0 mb-1">{props.RenderMap}</div>
            <div className="col-12">
              <div className="row">
                <input
                  type="text"
                  data-toggle="tooltip"
                  data-placement="left"
                  title="Service Name Required"
                  className="fillcolor p-2 col-sm-4 col-12  mb-0 bordercolor rounded-0"
                  name="service_name"
                  placeholder="Service Name"
                  value={props.service_name}
                  onChange={props.onHandleChange}
                />
                <input
                  type="text"
                  data-toggle="tooltip"
                  data-placement="left"
                  title="Service Price Required "
                  className="fillcolor p-2 col-sm-4 col-12  mb-0 bordercolor rounded-0"
                  name="service_price"
                  placeholder="Service Price in $!"
                  value={props.service_price}
                  onChange={props.onHandleChange}
                />
                <input
                  type="number"
                  data-toggle="tooltip"
                  data-placement="left"
                  title="Service time Required"
                  className="fillcolor p-2 col-sm-2 col-6  mb-0 bordercolor rounded-0"
                  placeholder="dur(min)"
                  value={props.service_time}
                  name="service_time"
                  onChange={props.onHandleChange}
                  min={1}
                />
                {props.payment_type !== "full" && (
                  <input
                    type="number"
                    data-toggle="tooltip"
                    data-placement="left"
                    title="Deposit Amount"
                    className="fillcolor p-2 col-sm-2 col-6  mb-0 bordercolor rounded-0"
                    placeholder="deposit"
                    value={props.service_deposit}
                    name="service_deposit"
                    onChange={props.onHandleChange}
                    min={0}
                  />
                )}
              </div>
            </div>
          </div>
          <div className=" col-12">
            <button
              type="button"
              className="btn d-block mxAuto White "
              onClick={serviceChange}
            >
              <PlusIcon />
            </button>
          </div>

          <div>
            <h6 className="mt-4 mb-2 Bold">Upload Images</h6>
            <p>* only png, jpeg, jpg images are accepted</p>
            <UploadComponent2 limit={7} imagesSet={props.imagesSet} multiple />
          </div>
          <h6 className="mt-4 mb-2 Bold">Amenities</h6>
          <div className="form-group row">
            <label htmlFor="Book" className="font16 col-8">
              Instant Booking
            </label>
            <div className="col-4 my-auto">
              <Switch
                id="Book"
                unCheckedChildren="OFF"
                checkedChildren="ON"
                checked={props.book}
                onChange={props.onBookChange}
                className="ml-auto"
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="WiFI" className="font16 col-8">
              Wi-Fi
            </label>
            <div className="col-4 my-auto">
              <Switch
                id="WiFi"
                unCheckedChildren="OFF"
                checkedChildren="ON"
                checked={props.WiFi}
                onChange={props.onWiFiChange}
                className="ml-auto"
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="Travel" className="font16 col-8">
              Mobile Service
            </label>
            <div className="col-4 my-auto">
              <Switch
                id="Travel"
                unCheckedChildren="OFF"
                checkedChildren="ON"
                checked={props.trevel_to_me}
                onChange={props.onTravelChange}
                className="ml-auto"
              />
            </div>
          </div>
          <h6 className="mt-4 mb-2 Bold">Social Links</h6>
          <div className="form-group">
            <Input
              size="large"
              placeholder="Facebook Link"
              name="Facebook"
              onChange={props.onHandleChange}
              prefix={<FacebookFilled />}
            />
            <Input
              size="large"
              placeholder="Instagram Link"
              name="Instagram"
              onChange={props.onHandleChange}
              prefix={<InstagramFilled />}
            />
          </div>

          <h6 className="mt-4 mb-2 Bold">Verification</h6>
          <p className="text-danger">
            Provide Front and Back image of your National ID
          </p>
          <p>* only png, jpeg, jpg images are accepted</p>
          <UploadComponent2 limit={2} imagesSet={props.verification} />

          <button
            className="btn White Orange d-block col-md-5 col-12 p-2 rounded-0 mxAuto"
            type="button"
            disabled={props.openModel}
            onClick={props.validation}
          >
            Submit Request
          </button>
          <p
            className="text-danger text-center mb-0 py-2"
            style={{ fontSize: "20px", fontWeight: "500" }}
          >
            {props.error}
          </p>
          <Modal
            className="OptPopUp"
            open={props.openModel}
            footer={null}
            closable={false}
          >
            <Spin spinning={props.loading}>
              <p className="text-center font-weight-bolder pt-4 Black">
                Please Wait, Sending Your Request
              </p>
            </Spin>
          </Modal>
        </div>

        <div className="col-10 col-lg-4 mxAuto paddingTop_3">
          <h4 className="font34">Why Black Booking?</h4>
          <p className="font17">
            Professionals have an excellent opportunity to increase their
            customer base. As a business owner, you will be able to make more
            money through direct appointments with clients using Black Booking.{" "}
          </p>
          <p className="font17">
            For your listing page, you will be provided with personalized
            booking forms and detailed services portfolios. By doing this, you
            will be able to let more people know about all of the services you
            tend to offer.
          </p>
          <p className="font17">
            Appointments scheduled in advance benefit all the stakeholders. What
            lies ahead for you in the days and weeks to come is known to you. If
            you present yourself as a worthy one and secure compelling reviews
            on Black Booking, you can land high ticket clients as well. You can
            give us all the information about your business and the service you
            offer, and we will find new customers for you.
          </p>
        </div>
      </div>

      <h1 className="text-center pt-5">4 Step Process</h1>
      <div className="col-11 mxAuto">
        <div className="row pt-3">
          <div className="col-md-6 col-11 mxAuto mt-2 shadow-sm rounded-0">
            <div className="card rounded-0   ">
              <div className="card-body p-4 ">
                <h3 className="pt-3  uAccount">Register Your Business</h3>
                <p className="card-text ">
                  Make sure all the necessary information about your business is
                  registered, such as its name, location, and other required
                  information.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-11 mxAuto mt-2 shadow-sm rounded-0 ">
            <div className="card rounded-0   ">
              <div className="card-body p-4 ">
                <h3 className="pt-3  uAccount">Add Service Details</h3>
                <p className="card-text ">
                  Provide us with all your service details and their pricing.
                  Evaluate minute details explicitly for better customer
                  understanding.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row pt-3">
          <div className="col-md-6 col-11 mxAuto mt-2 shadow-sm rounded-0">
            <div className="card rounded-0   ">
              <div className="card-body p-4 ">
                <h3 className="pt-3  uAccount">Get Bookings</h3>
                <p className="card-text ">
                  When you are set up on Black Booking, our booking portal will
                  allow you to receive booking appointments. Your best effort
                  should be put into entertaining them.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-11 mxAuto mt-2 shadow-sm rounded-0">
            <div className="card rounded-0   ">
              <div className="card-body p-4 ">
                <h3 className="pt-3  uAccount">Get Paid</h3>
                <p className="card-text ">
                  You will see the payment reflected in your account after the
                  booking has been processed and confirmed.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessForm;
